<template>
  <div class="contant d-flex flex-row align-center justify-center" :class="{ mobile: isMobile }">

    <div class="left">
        <img v-if="!isMobile" src="@/assets/logo.png" height="90" alt="">
        <img v-else src="@/assets/mobile/m_logo.png" width="170"  alt="">
        <div class="des mt-7">Upward Wallet is your multi-chain crypto wallet designed for managing both fungible tokens (FTs) and a wide range of Non-fungible Tokens (NFTs)</div>
        <div class="download mt-8">
          <div class="download d-flex flex-row ">
            <img src="@/assets/d_android.png"   @mouseenter="mousedAndroid()"
          @mouseleave ="leaveAndroid()" @click="downAndroid"  height="50" alt="">
         
          <img  @mouseenter="mousedGoogle()"
          @mouseleave ="leaveGoogle()" src="@/assets/d_googleplay.png"   @click="downGoogle" 
         class="ml-6 testflight" height="50" alt="">
          </div>
          <div class="download mt-lg-4 mt-2">
        
         <img  @mouseenter="mousedAppleStore()"
          @mouseleave ="leaveAppleStore()" src="@/assets/d_appstore.png"   @click="downAppstore" 
         class=" testflight" height="50" alt="">
         <img src="@/assets/d_testflight.png"   @click="downIos"    @mouseenter="mousedIos()"
          @mouseleave ="leaveIos()" class="ml-6 testflight"   height="50" alt="">
        </div>
  
        

          <img v-if="isShowAndroid && !isMobile" class="android_code" src="@/assets/download_android.png" width="185" alt="">
          <img v-if="isShowIos && !isMobile" class="ios_code" src="@/assets/download_ios.png" width="185" alt="">
          <img v-if="isShowFGoogle && !isMobile" class="google_code" src="@/assets/download_google.png" width="185"  alt="">
          <img v-if="isShowAppleStore && !isMobile" class="apple_code" src="@/assets/download_appstore.png" width="185" alt="">

          
        </div>
       
    </div>
    <div class="right">
      <img v-if="!isMobile" src="@/assets/bg1.png"  height="650" alt="">
      <img v-else src="@/assets/bg1.png"  height="502" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      isShowAndroid:false,
      isShowIos:false,
      isShowFGoogle:false,
      isShowAppleStore:false
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  async mounted() {
  
   },

   
 
  methods: {
    downGoogle(){
      window.open("https://play.google.com/store/apps/details?id=com.uptick.wallet","_blank");
    },
    downAppstore(){
    
      window.open("https://apps.apple.com/us/app/upward-wallet/id6472655450","_blank");
    },
  
    downAndroid(){
      window.open("https://file.uptick.network/app/Upwardwallet.apk","_blank");
    },
    downIos(){
      window.open('https://testflight.apple.com/join/X5GWHcO1','_blank')
    },
    mousedAndroid(){
        this.isShowAndroid = true
    },
    leaveAndroid(){
        this.isShowAndroid = false
    },
    mousedIos(){
        this.isShowIos = true
    },
    leaveIos(){
        this.isShowIos = false
    },
    mousedGoogle(){
      this.isShowFGoogle = true
    },
    leaveGoogle(){
      this.isShowFGoogle = false
    },
    mousedAppleStore(){
      this.isShowAppleStore =true
    },
    leaveAppleStore(){
      this.isShowAppleStore =false
    }
  
  
  },
};
</script>

<style lang="scss" scoped>
.contant {
  width: 100%;
  height: 100%;
  .left{
    .des{
      width: 487px;
      font-family: Helvetica;
	font-size: 17px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 1px;
	color: #ffffff;
    }
    .download{
      position: relative;
      img{
        cursor: pointer;
      }
      .android_code{
        position: absolute;
        top: -189px;
        left: 20px;
      }
      .ios_code{
        position: absolute;
        top: -123px;
        left: 270px;
       
      }
      .google_code{
      

        position: absolute;
        top: -189px;
        left: 270px;

      }
      .apple_code{
      

        position: absolute;
        top: -123px;
        left: 20px;

      }
    }
    .description{
      width: 487px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: 1px;
    color: #ffffff;
    font-style: italic;
 
    
    }
  }




 
  &.mobile {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 90px;
   .left{
    display: flex;
    flex-direction: column;
    align-items: center;
    .des{
      width: 75%;
      text-align: center;
    }
    .description{
      width: 75%;
      text-align: center;
    }
    .testflight{
      margin-top:8px;
      margin-left: 0px !important;
    }
    .download{
      display: flex !important;
    flex-direction: column !important;
    align-items: center;
    width: 100%;
    position: relative !important;
    }
   }
  }
}
</style>

