<template>
      <v-app  class="mainApp" :class="{'shareImg':!isShowFooter}" >
  
        <v-main  style="padding-top: 0px !important; margin-bottom:50px；  margin:  auto;">
            <v-container >
                <router-view ></router-view>
            </v-container>
        </v-main>
      
        
        <Footer  v-if="isShowFooter"/>
    </v-app>
</template>

<script>

import Footer from "./footer";
import { loginOut } from "../api/home";
export default {
  name: 'Layout',
  
  components: {
    Footer,
  },

  data:function(){
    return{
        isopenCookies:true,
    }
  },
  mounted(){
     let cookies = localStorage.getItem("acceptCookie");
   if(cookies ){
      this.isopenCookies = false
   }
  },
  methods:{
      openCookies(e){
      this.isopenCookies = e
    },
    setStates(e){
      if(e){
         console.log("setStates",e);
      }else{
        loginOut()
      }
    },
  },
  computed: {
    // key() {
    //   // 只要保证 key 唯一性就可以了，保证不同页面的 key 不相同
    //   console.log("this.$route.fullPath,",this.$route.fullPath);
    //   return this.$route.fullPath
    // }

      key() {
                return this.$route.name !== undefined ?
                    this.$route.name + new Date() :
                    this.$route + new Date();
            },
            isShowFooter:function(){
  console.log("wxl --- isShowFooter",this.$route.name == 'Home' );
    return this.$route.name == 'Home' 
}
  },
}
</script>

<style lang="scss" scoped>
.container{
  padding: 0 12px;
}

    .mainApp{
        /* background-color: violet !important; */
        background-image: url(@/assets/bg.jpg) !important;
        background-size: cover !important;
    }
    .shareImg{
      background-image: url(@/assets/share_bg.jpeg) !important;
        background-size: contain !important;
     
      
    }
</style>