<template>
    <div class="contain">
        <img src="@/assets/h5_01.png" width="40" height="40" alt="">
        <div class="font-20 mt-5">Unlock Upward Genesis Grants!</div>
        <div class="font-14 mt-5">Download the Upward app and claim weekly rewards with the Upward Genesis Grant Campaign until July 31st, 2024!</div>
        <img src="@/assets/h5_02.png" width="270" class="shareImg" alt="">
        <img src="@/assets/h5_03.png" width="100" class="upward" alt="">
        <button class="btn" @click="toHome">Download App</button>
        <div class="line"></div>
        <div class="font-20">How to claim Grants?</div>
        <div class="steps">
            Step 1 | Set Up Your Receiving Wallet
        </div>
        <img src="@/assets/h5_04.png" width="100%" class="mt-5" alt="">
        <div class="steps">
            Step 2 | Enter Your Inviter's Code
        </div>
        <img src="@/assets/h5_05.png" width="100%"  class="mt-5" alt="">
        <div class="steps">
            Step 3 | Get Free Gas
        </div>
        <img src="@/assets/h5_06.png" width="100%"  class="mt-5" alt="">
        <div class="steps">
            Step 4 | Claim Your Weekly Grants
        </div>
        <img src="@/assets/h5_07.png" width="100%"  class="mt-5" alt="">
        <div class="notes mt-10">Notes:</div>
        <div class="notes mt-1 mb-12">After you complete the claim steps in order, your inviter will receive extra UPTICK rewards.</div>
    </div>
</template>

<script>

export default {
    name: 'share',

    components: {

    },
    methods:{
        toHome(){
            this.$router.push({name:'Home'})
        }
    }
}
</script>
<style lang="scss" scoped>
.contain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 20px 0 20px;

    .font-20 {
        font-family: Helvetica;
        font-size: 21px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: 0px;
        color: #000000;
        text-align: center;
    }
    .notes{
        width: 100%;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #000000;
        text-align: left; 

    }
    .font-14 {
        width: 90%;
        font-family: Helvetica;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 18px;
        letter-spacing: 0px;
        color: #000000;
        text-align: center;
    }

    .shareImg {
        position: absolute;
        top: 230px;
        right: 0;
    }

    .upward {
        margin-top: 230px;
    }

    .btn {
        width: 80%;
        height: 60px;
        background-color: #7d3de7;
        border-radius: 30px;
        font-family: Helvetica;
        font-size: 21px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-top: 17px;
    }

    .line {
        width: 100%;
        height: 1px;
        background-color: #7d3de7;
        border: solid 1px #b5b1d6;
        margin: 50px 0;

    }

    .steps {
        width: 100%;
        height: 36px;
        background-color: rgba(102, 102, 102, 0.1);
        border-radius: 18px;
        margin-top: 38px;
        text-align: center;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 35px;
        letter-spacing: 0px;
        color: #000000;
    }
}
</style>